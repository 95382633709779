import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const BackgroundPickerPage = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formThemeBackground = watch('theme.background');
  const formThemeBackgroundName = watch('theme.background.name');

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.BACKGROUND')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.BACKGROUND-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 w100p flex-wrap jc-center ">
          {WebsiteBuilderBackgrounds.images.map((item, index) => (
            <ButtonComponent
              isCustom
              key={index}
              className={`website-background-image-item flex-grow flex-w30p min-w120 ${
                formThemeBackground === item.name || formThemeBackgroundName === item.name ? 'selected' : ''
              }`}
              style={{
                backgroundImage: `url(${item.url})`,
              }}
              onClick={() => setValue('theme.background', item.name)}
            >
              {(formThemeBackground === item.name || formThemeBackgroundName === item.name) && (
                <Icon className="text-blue selected-icon">check_circle</Icon>
              )}
            </ButtonComponent>
          ))}
          {WebsiteBuilderBackgrounds.colors.map((item, index) => (
            <ButtonComponent
              isCustom
              key={index}
              className={`website-background-image-item flex-grow flex-w30p min-w120 ${
                formThemeBackground === item.name || formThemeBackgroundName === item.name ? 'selected' : ''
              }`}
              style={{
                backgroundColor: item.hex,
              }}
              onClick={() => setValue('theme.background', item.name)}
            >
              {(formThemeBackground === item.name || formThemeBackgroundName === item.name) && (
                <Icon className="text-blue selected-icon">check_circle</Icon>
              )}
            </ButtonComponent>
          ))}
        </div>
      </div>
    </>
  );
};

export default BackgroundPickerPage;
