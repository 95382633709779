import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const YoutubeVideoPage = () => {
  const { t } = useTranslation();
  const [embedID, setEmbedID] = useState<string>('');

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formVideoUrl = watch('video.url');
  const formVideoCustomText = watch('video.customText');

  const baseEmbedUrl = `https://www.youtube.com/embed/${embedID}`;

  const formatYoutubeLink = (link: string) => {
    if (link.includes('watch')) {
      const startIndex = link.indexOf('v=');
      const id = link.slice(startIndex + 2, startIndex + 13);
      setEmbedID(id);
    }
    if (link.includes('youtu.be')) {
      const startIndex = link.indexOf('be/');
      const id = link.slice(startIndex + 3, startIndex + 14);
      setEmbedID(id);
    }
    if (link.includes('embed')) {
      const startIndex = link.indexOf('embed/');
      const id = link.slice(startIndex + 6, startIndex + 17);
      setEmbedID(id);
    }
  };

  useEffect(() => {
    if (formVideoUrl) {
      formatYoutubeLink(formVideoUrl);
    }
  }, [formVideoUrl]);

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.YOUTUBE-VIDEO')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.YOUTUBE-VIDEO-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUTUBE-EMBED-CODE')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.YOUTUBE-EMBED-CODE-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <input
              className="mb8"
              placeholder={t('WEBSITE-BUILDER.YOUR-MESSAGE')}
              value={formVideoCustomText ?? ''}
              onChange={(event) => setValue('video.customText', event.target.value)}
            />
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.PASTE-YOUTUBE-CODE')}
                value={formVideoUrl ?? ''}
                onChange={(event) => setValue('video.url', event.target.value)}
              />
            </div>
            {embedID && (
              <iframe
                className="mt20"
                width={'100%'}
                referrerPolicy="no-referrer-when-downgrade"
                height={315}
                src={baseEmbedUrl}
                title={'YouTube video player'}
                allowFullScreen
                allow={
                  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default YoutubeVideoPage;
