import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import AddLinkModal from '@/components/utility/modals/AddLinkModal';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const PlatformLinks = () => {
  const { t } = useTranslation();

  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formFacebookLink = watch('platformLinks.socialLinks.facebook');
  const formInstagramLink = watch('platformLinks.socialLinks.instagram');
  const formTikTokLink = watch('platformLinks.socialLinks.tikTok');
  const formTwitterLink = watch('platformLinks.socialLinks.twitter');
  const formSnapchatLink = watch('platformLinks.socialLinks.snapchat');
  const formSpotifyLink = watch('platformLinks.musicLinks.spotify');
  const formDeezerLink = watch('platformLinks.musicLinks.deezer');
  const formYoutubeLink = watch('platformLinks.musicLinks.youTube');
  const formAppleLink = watch('platformLinks.musicLinks.appleMusic');
  const formAmazonLink = watch('platformLinks.musicLinks.amazonMusic');
  const formBoomplayLink = watch('platformLinks.musicLinks.boomplay');
  const formAudiomackLink = watch('platformLinks.musicLinks.audiomack');

  const socialLinks = useMemo(
    () => [
      {
        title: 'BRAND.FACEBOOK',
        logo: 'facebook',
        link: formFacebookLink ?? '',
      },
      {
        title: 'BRAND.INSTAGRAM',
        logo: 'instagram',
        link: formInstagramLink ?? '',
      },
      {
        title: 'BRAND.TIKTOK',
        logo: 'tiktok',
        link: formTikTokLink ?? '',
      },
      {
        title: 'BRAND.TWITTER',
        logo: 'twitter',
        link: formTwitterLink ?? '',
      },
      {
        title: 'BRAND.SNAPCHAT',
        logo: 'snapchat',
        link: formSnapchatLink ?? '',
      },
    ],
    [formFacebookLink, formInstagramLink, formSnapchatLink, formTikTokLink, formTwitterLink]
  );

  const musicLinks = useMemo(
    () => [
      {
        title: 'BRAND.SPOTIFY',
        logo: 'spotify',
        link: formSpotifyLink ?? '',
      },
      {
        title: 'BRAND.DEEZER',
        logo: 'deezer',
        link: formDeezerLink ?? '',
      },
      {
        title: 'BRAND.YOUTUBE-MUSIC',
        logo: 'youtube',
        link: formYoutubeLink ?? '',
      },
      {
        title: 'BRAND.APPLE-MUSIC',
        logo: 'apple-music',
        link: formAppleLink ?? '',
      },
      {
        title: 'BRAND.AMAZON-MUSIC',
        logo: 'amazon-music',
        link: formAmazonLink ?? '',
      },
      {
        title: 'BRAND.BOOMPLAY',
        logo: 'boomplay',
        link: formBoomplayLink ?? '',
      },
      {
        title: 'BRAND.AUDIOMACK',
        logo: 'audiomack',
        link: formAudiomackLink ?? '',
      },
    ],
    [
      formAmazonLink,
      formAppleLink,
      formAudiomackLink,
      formBoomplayLink,
      formDeezerLink,
      formSpotifyLink,
      formYoutubeLink,
    ]
  );

  const setLink = (link: string) => {
    if (modalTitle.includes('FACEBOOK')) {
      setValue('platformLinks.socialLinks.facebook', link);
    }
    if (modalTitle.includes('INSTAGRAM')) {
      setValue('platformLinks.socialLinks.instagram', link);
    }
    if (modalTitle.includes('TIKTOK')) {
      setValue('platformLinks.socialLinks.tikTok', link);
    }
    if (modalTitle.includes('TWITTER')) {
      setValue('platformLinks.socialLinks.twitter', link);
    }
    if (modalTitle.includes('SNAPCHAT')) {
      setValue('platformLinks.socialLinks.snapchat', link);
    }
    if (modalTitle.includes('SPOTIFY')) {
      setValue('platformLinks.musicLinks.spotify', link);
    }
    if (modalTitle.includes('DEEZER')) {
      setValue('platformLinks.musicLinks.deezer', link);
    }
    if (modalTitle.includes('YOUTUBE-MUSIC')) {
      setValue('platformLinks.musicLinks.youTube', link);
    }
    if (modalTitle.includes('APPLE-MUSIC')) {
      setValue('platformLinks.musicLinks.appleMusic', link);
    }
    if (modalTitle.includes('AMAZON-MUSIC')) {
      setValue('platformLinks.musicLinks.amazonMusic', link);
    }
    if (modalTitle.includes('BOOMPLAY')) {
      setValue('platformLinks.musicLinks.boomplay', link);
    }
    if (modalTitle.includes('AUDIOMACK')) {
      setValue('platformLinks.musicLinks.audiomack', link);
    }
  };

  const clearLink = useCallback(
    (platform: string) => {
      if (platform.includes('FACEBOOK')) {
        setValue('platformLinks.socialLinks.facebook', undefined);
      }
      if (platform.includes('INSTAGRAM')) {
        setValue('platformLinks.socialLinks.instagram', undefined);
      }
      if (platform.includes('TIKTOK')) {
        setValue('platformLinks.socialLinks.tikTok', undefined);
      }
      if (platform.includes('TWITTER')) {
        setValue('platformLinks.socialLinks.twitter', undefined);
      }
      if (platform.includes('SNAPCHAT')) {
        setValue('platformLinks.socialLinks.snapchat', undefined);
      }
      if (platform.includes('SPOTIFY')) {
        setValue('platformLinks.musicLinks.spotify', undefined);
      }
      if (platform.includes('DEEZER')) {
        setValue('platformLinks.musicLinks.deezer', undefined);
      }
      if (platform.includes('YOUTUBE-MUSIC')) {
        setValue('platformLinks.musicLinks.youTube', undefined);
      }
      if (platform.includes('APPLE-MUSIC')) {
        setValue('platformLinks.musicLinks.appleMusic', undefined);
      }
      if (platform.includes('AMAZON-MUSIC')) {
        setValue('platformLinks.musicLinks.amazonMusic', undefined);
      }
      if (platform.includes('BOOMPLAY')) {
        setValue('platformLinks.musicLinks.boomplay', undefined);
      }
      if (platform.includes('AUDIOMACK')) {
        setValue('platformLinks.musicLinks.audiomack', undefined);
      }
    },
    [setValue]
  );

  const hasLink = useMemo(() => {
    if (modalTitle.includes('FACEBOOK')) {
      return formFacebookLink ?? '';
    }
    if (modalTitle.includes('INSTAGRAM')) {
      return formInstagramLink ?? '';
    }
    if (modalTitle.includes('TIKTOK')) {
      return formTikTokLink ?? '';
    }
    if (modalTitle.includes('TWITTER')) {
      return formTwitterLink ?? '';
    }
    if (modalTitle.includes('SNAPCHAT')) {
      return formSnapchatLink ?? '';
    }
    if (modalTitle.includes('SPOTIFY')) {
      return formSpotifyLink ?? '';
    }
    if (modalTitle.includes('DEEZER')) {
      return formDeezerLink ?? '';
    }
    if (modalTitle.includes('YOUTUBE-MUSIC')) {
      return formYoutubeLink ?? '';
    }
    if (modalTitle.includes('APPLE-MUSIC')) {
      return formAppleLink ?? '';
    }
    if (modalTitle.includes('AMAZON-MUSIC')) {
      return formAmazonLink ?? '';
    }
    if (modalTitle.includes('BOOMPLAY')) {
      return formBoomplayLink ?? '';
    }
    if (modalTitle.includes('AUDIOMACK')) {
      return formAudiomackLink ?? '';
    }

    return '';
  }, [
    formAmazonLink,
    formAppleLink,
    formAudiomackLink,
    formBoomplayLink,
    formDeezerLink,
    formFacebookLink,
    formInstagramLink,
    formSnapchatLink,
    formSpotifyLink,
    formTikTokLink,
    formTwitterLink,
    formYoutubeLink,
    modalTitle,
  ]);

  return (
    <>
      {isLinkModalOpen && (
        <AddLinkModal
          title={modalTitle}
          isOpen={isLinkModalOpen}
          isProfile={true}
          value={hasLink}
          onClose={() => setIsLinkModalOpen(false)}
          outputLink={(link: string) => setLink(link)}
        />
      )}
      <h3>{t('WEBSITE-BUILDER.PLATFORM-LINKS')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.PLATFORM-LINKS-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.SOCIAL-PROFILE-LINKS')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.SOCIAL-PROFILE-LINKS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="d-flex gap8 flex-wrap">
              {socialLinks.map((item) => (
                <div key={item.title} style={{ position: 'relative' }}>
                  <Button
                    className={`m0 link-button border-btn-grey ${item.link ? 'selected pr48' : ''}`}
                    onClick={() => {
                      setModalTitle(item.title);
                      setIsLinkModalOpen(true);
                    }}
                  >
                    <Icon className="pr8 mt-auto mb-auto">
                      <img src={`/images/logos/${item.logo}-logo-white.svg`} alt="" />
                    </Icon>
                    <span className="mt-auto mb-auto">{t(item.title)}</span>
                    {!item.link && <Icon className="pl8 mt-auto mb-auto">add</Icon>}
                    {item.link && (
                      <Icon className="pl8 mt-auto mb-auto" onClick={() => clearLink(item.title)}>
                        cancel
                      </Icon>
                    )}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>

        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.MUSIC-PROFILE-LINKS')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.MUSIC-PROFILE-LINKS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="d-flex gap8 flex-wrap">
              {musicLinks.map((item) => (
                <div key={item.title} style={{ position: 'relative' }}>
                  <Button
                    className={`m0 link-button border-btn-grey ${item.link ? 'selected pr48' : ''}`}
                    onClick={() => {
                      setModalTitle(item.title);
                      setIsLinkModalOpen(true);
                    }}
                  >
                    <Icon className="pr8 mt-auto mb-auto">
                      <img src={`/images/logos/${item.logo}-logo-white.svg`} alt="" />
                    </Icon>
                    <span className="mt-auto mb-auto">{t(item.title)}</span>
                    {!item.link && <Icon className="pl8 mt-auto mb-auto">add</Icon>}
                    {item.link && (
                      <Icon className="pl8 mt-auto mb-auto" onClick={() => clearLink(item.title)}>
                        cancel
                      </Icon>
                    )}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformLinks;
