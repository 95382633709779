import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Switch } from '@mui/material';

import AddLinkModal from '@/components/utility/modals/AddLinkModal';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const CommunityPage = () => {
  const { t } = useTranslation();

  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formCustomText = watch('community.customText');
  const formCollectEmail = watch('mailingList.collectEmails');
  const formCollectPhoneNumbers = watch('mailingList.collectPhoneNumbers');
  const formDiscord = watch('community.discordChannel');
  const formInstagram = watch('community.instagramBroadcast');
  const formWhatsApp = watch('community.whatsAppGroup');

  const setLink = useCallback(
    (link: string) => {
      if (modalTitle.includes('INSTAGRAM')) {
        setValue('community.instagramBroadcast', link);
      }
      if (modalTitle.includes('DISCORD')) {
        setValue('community.discordChannel', link);
      }
      if (modalTitle.includes('WHATSAPP')) {
        setValue('community.whatsAppGroup', link);
      }
    },
    [modalTitle, setValue]
  );

  const hasLink = useMemo(() => {
    if (modalTitle.includes('INSTAGRAM')) {
      return formInstagram ?? '';
    }
    if (modalTitle.includes('DISCORD')) {
      return formDiscord ?? '';
    }
    if (modalTitle.includes('WHATSAPP')) {
      return formWhatsApp ?? '';
    }
    return '';
  }, [formDiscord, formInstagram, formWhatsApp, modalTitle]);

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.COMMUNITY-PAGE')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.COMMUNITY-PAGE-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUR-MESSAGE')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.COMMUNITY-PAGE-DESCRIPTION-1')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="pos-rel">
              <textarea
                placeholder={t('WEBSITE-BUILDER.JOIN-THE-GANG')}
                value={formCustomText ?? 'Join our community and never miss an update'}
                maxLength={100}
                onChange={(event) => setValue('community.customText', event.target.value)}
              />
              <div className="textarea-counter">{formCustomText?.length ?? 0}/100</div>
            </div>
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUR-MAILING-LISTS')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.YOUR-MAILING-LISTS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="card-inner">
              <div className="d-flex jc-space-between">
                <p className="mt-auto mb-auto">{t('WEBSITE-BUILDER.COLLECT-EMAILS')}</p>
                <Switch
                  className={`cursor-pointer ${formCollectEmail ? 'selected' : ''}`}
                  defaultChecked={formCollectEmail}
                  checked={formCollectEmail}
                  onClick={() => {
                    setValue('mailingList.collectEmails', !formCollectEmail);
                  }}
                />
              </div>
              <div className="d-flex jc-space-between">
                <p className="mt-auto mb-auto">{t('WEBSITE-BUILDER.COLLECT-PHONE-NUMBERS')}</p>
                <Switch
                  className={`cursor-pointer ${formCollectPhoneNumbers ? 'selected' : ''}`}
                  defaultChecked={formCollectPhoneNumbers}
                  checked={formCollectPhoneNumbers}
                  onClick={() => {
                    setValue('mailingList.collectPhoneNumbers', !formCollectPhoneNumbers);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUR-COMMUNITY-LINKS')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.YOUR-COMMUNITY-LINKS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            {isLinkModalOpen && (
              <AddLinkModal
                title={modalTitle}
                isOpen={isLinkModalOpen}
                value={hasLink}
                onClose={() => setIsLinkModalOpen(false)}
                outputLink={(link: string) => setLink(link)}
              />
            )}
            <div className="d-flex gap8 flex-wrap">
              <div style={{ position: 'relative' }}>
                <Button
                  className={`m0 link-button border-btn-grey ${formInstagram ? 'selected pr48' : ''}`}
                  onClick={() => {
                    setModalTitle('WEBSITE-BUILDER.INSTAGRAM-BROADCAST-CHANNEL');
                    setIsLinkModalOpen(true);
                  }}
                >
                  <Icon className="pr8 mt-auto mb-auto">
                    <img src="/images/logos/instagram-logo-white.svg" alt="" />
                  </Icon>
                  <span className="mt-auto mb-auto">{t('WEBSITE-BUILDER.INSTAGRAM-BROADCAST-CHANNEL')}</span>
                  {!formInstagram && <Icon className="pl8 mt-auto mb-auto">add</Icon>}
                  {formInstagram && (
                    <Icon
                      className="pl8 mt-auto mb-auto"
                      onClick={() => setValue('community.instagramBroadcast', undefined)}
                    >
                      cancel
                    </Icon>
                  )}
                </Button>
              </div>

              <div style={{ position: 'relative' }}>
                <Button
                  className={`m0 link-button border-btn-grey ${formWhatsApp ? 'selected pr48' : ''}`}
                  onClick={() => {
                    setModalTitle('WEBSITE-BUILDER.WHATSAPP-GROUP');
                    setIsLinkModalOpen(true);
                  }}
                >
                  <Icon className="pr8 mt-auto mb-auto">
                    <img src="/images/logos/whatsapp-logo-white.svg" alt="" />
                  </Icon>
                  <span className="mt-auto mb-auto">{t('WEBSITE-BUILDER.WHATSAPP-GROUP')}</span>
                  {!formWhatsApp && <Icon className="pl8 mt-auto mb-auto">add</Icon>}
                  {formWhatsApp && (
                    <Icon
                      className="pl8 mt-auto mb-auto"
                      onClick={() => setValue('community.whatsAppGroup', undefined)}
                    >
                      cancel
                    </Icon>
                  )}
                </Button>
              </div>

              <div style={{ position: 'relative' }}>
                <Button
                  className={`m0 link-button border-btn-grey ${formDiscord ? 'selected pr48' : ''}`}
                  onClick={() => {
                    setModalTitle('WEBSITE-BUILDER.DISCORD-CHANNEL');
                    setIsLinkModalOpen(true);
                  }}
                >
                  <Icon className="pr8 mt-auto mb-auto">
                    <img src="/images/logos/discord-logo-white.svg" alt="" />
                  </Icon>
                  <span className="mt-auto mb-auto">{t('WEBSITE-BUILDER.DISCORD-CHANNEL')}</span>
                  {!formDiscord && <Icon className="pl8 mt-auto mb-auto">add</Icon>}
                  {formDiscord && (
                    <Icon
                      className="absolute pl8 mt-auto mb-auto"
                      onClick={() => setValue('community.discordChannel', undefined)}
                    >
                      cancel
                    </Icon>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityPage;
